<template>
  <div class="px-5">
    <Form
      title="Editar usuario"
      ref="userForm"
      :deletable="true"
      :loading.sync="loading"
      :passwordLoading.sync="passwordLoading"
      @save="save"
      @changePassword="changePassword"
    />
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import Form from "./Form.vue"
export default {
  components: {
    Form
  },
  data() {
    return {
      loading: true,
      passwordLoading: false
    }
  },
  computed: {
    user() {
      return this.$store.getters["auth/getUser"]
    }
  },
  methods: {
    changePassword() {
      let form = this.$refs.userForm.passwords_form
      this.passwordLoading = true
      this.$store
        .dispatch("user/changePassword", {
          id: this.$route.params.id,
          payload: {
            old_password: form.old_password,
            new_password: form.new_password
          }
        })
        .then((response) => {
          this.passwordLoading = false
          notify().then(({ notification }) => {
            notification("changePassword", response.code, this.$snotify)
          })
        })
        .catch((error) => {
          this.passwordLoading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    save() {
      let form = this.$refs.userForm.form
      this.loading = true
      this.$store
        .dispatch("global/update", {
          payload: {
            id: form.id,
            id_role: form.id_role,
            first_name: form.first_name,
            last_name: form.last_name,
            email: form.email,
            profile_picture: form.profile_picture
          },
          route: "/user/update",
          module: "user"
        })
        .then((response) => {
          notify().then(({ notification }) => {
            notification("master-update", response.code, this.$snotify)
          })
          this.loading = false
          if ([200, 201].includes(response.code)) {
            this.$router.push("/usuarios")
          }
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    getItem() {
      this.$store
        .dispatch("global/getItem", {
          route: "/user",
          module: "user",
          id: this.$route.params.id
        })
        .then((response) => {
          this.$refs.userForm.form = response.data
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    }
  },
  created() {
    this.getItem()
  }
}
</script>
